import React from 'react';
import hospital from '../images/hospital.png';
import oncology from '../images/oncology.png';
import metabolic from '../images/metabolic.png';
 
const PipelineTable = () => {
  const data = [
    {
      indication: "Oncology",
      target: "HDAC8",
      progress: {
        enabling: 50,
      },
      icon: oncology,
    },
    {
      indication: "Oncology",
      target: "KRAS – G12C",
      progress: {
        enabling: 30,
      },
      icon: oncology,
    },
    {
      indication: "Angelman Syndrome",
      target: "E6AP",
      progress: {
        enabling: 30,
      },
      icon: hospital,
    },
    {
      indication: "Oncology",
      target: "Neuropilin",
      progress: {
        enabling: 30,
      },
      icon: oncology,
    },
    {
      indication: "Metabolic Disorders",
      target: "RBP4",
      progress: {
        enabling: 30,
      },
      icon: metabolic,
    },
  ];
 
  return (
    <section className="bg-black text-white  px-4 sm:px-8 lg:px-24 xl:px-32  font-sofia h-[100vh]">
      <div className="container mx-auto">
        <h3 className="text-5xl font-bold p-2 mb-8 text-[#6FC5F0] text-start">Pipeline</h3>
        <div className="overflow-x-auto ">
          <table className="min-w-full bg-black rounded-lg border h-96 w-[20%] border-[#535353] text-lg">
            <thead >
              <tr className="border-b border-[#535353] ">
                <th className="px-4 py-2 p-2 text-center border-r border-[#535353] text-2xl">Disease Indication</th>
                <th className="px-4 py-2 text-center border-r border-[#535353] text-2xl">Target</th>
                <th className="px-4 py-2 text-center border-r border-[#535353] text-2xl">Discovery</th>
                <th className="px-4 py-2 text-center border-r border-[#535353] text-2xl">Lead Optimization</th>
                <th className="px-4 py-2 text-center text-2xl">Ind - Enabling</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="border-b border-gray-800">
                  <td className="px-4 py-2 flex items-center  text-center border-r border-gray-800">
                    <div className="flex items-center  mr-4 pr-4 border-r border-gray-800">
                      <img
                        src={row.icon}
                        alt={`${row.indication} icon`}
                        className="w-6 h-6 mr-2"
                      />
                    </div>
                    <div className='pl-20 text-2xl'> {row.indication}</div>
                    
                  </td>
                  <td className="px-4 py-2 border-r pl-20 text-2xl border-gray-800">{row.target}</td>
                  <td colSpan="3" className="px-4 py-2">
                    <div className="bg-gray-700 h-3.5 rounded-full relative w-full border border-gray-800">
                      <div
                        className="absolute top-0 left-0 h-3 bg-gradient-to-r from-orange-500 to-purple-500 rounded-full"
                        style={{ width: `${row.progress.enabling}%` }}
                      ></div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};
 
export default PipelineTable;
 
 