import React, { useState } from 'react';
import riyaz from '../images/Dr Riyaz Photo 4.jpeg'
import poorna from '../images/poorna.png'
import venkat from '../images/venkat.jpeg'
import jeevan from '../images/jeevan.jpeg'
import antony from '../images/antony.jpeg'
import irfan from '../images/irfan.jpeg'
import pavani from '../images/pavani.jpeg'
import niharika from '../images/niharika.jpeg'
import pavan from '../images/pavan.jpg'
import pavanreddy from '../images/pavanreddy.jpeg'
import sadiq from '../images/sadiq.jpeg'
import rami from '../images/rami.jpg'
import gayatri from '../images/gayatri.jpg'
import Aiicon from '../images/Ai ICON.png'
import { FaLinkedin } from 'react-icons/fa';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';

 
 
const AboutUs = () => {
 
  const [showPopup, setShowPopup] = useState(false);
  const [selectedFounder, setSelectedFounder] = useState(null);
 
  const founders = [
    {
      name: 'Dr. Riyaz Syed',
      designation: 'Founder & CEO',
      image: riyaz,
      description: `
      Dr. Riyaz is a distinguished scientist with a Ph.D. in Medicinal Chemistry and over 15 years of experience in cancer drug discovery.
      He has a proven track record in designing small molecules targeting kinases and protein degradation (PROTAC), credited with 50 publications and three patents, including a joint patent with Harvard Medical School and NUS Singapore.
     
      His expertise spans knowledge-based drug design, Structure-Based Drug Design (SBDD), Ligand-Based Drug Design (LBDD), retrosynthesis, Structure-Activity Relationship (SAR) analysis, patent space exploration, and effective grant writing.
      Dr. Riyaz has led international collaborations, managed multi-disciplinary teams, and executed projects with CRO and CRAMS.
 
      Dr. Riyaz also has significant management experience in startups, from ideation to MVP development, business model generation, investor pitching, and marketing strategy.
      He holds certifications in Business & Strategy from Wharton Business School, Harvard University, and IIM Bangalore.
 
      He has worked as a Consulting Scientist, Scientific Advisor, CSIR-Pool Scientist, Post-Doctoral Research Associate, and Junior Scientist, contributing extensively to research and development in cancer therapeutics.
    `,
      linkedin: 'https://www.linkedin.com/in/dr-riyaz-syed',
    },
    {
      name: 'Dr. Poornachandra',
      designation: 'Co-Founder, Head - Molecular Biology',
      image: poorna,
      description: 'Dr. Poornachandra brings over a decade of experience in cancer biology, molecular biology, drug discovery, and precision medicine to his role as co-founder and Director of the Biology Division at Centella. Dr. Poornachandra holds a Ph.D. from the Indian Institute of Chemical Technology, Hyderabad, where his research focused on nanobiotechnology and chemical biology. He successfully fabricated and characterized metal nanoparticles with potential anti-proliferative and anti-microbial properties. Additionally, his doctoral work explored the development of drug carrier systems for targeted cancer therapy and the screening of chemical libraries against various anti-cancer targets, including mechanistic studies.',
      linkedin: 'https://www.linkedin.com/in/dr-poornachandra',
    },

    {
      name: 'Jeevan Rebba',
      designation: 'Chief Strategy Officer',
      image: jeevan,
      description: 'With over 20 years in life sciences and strategy consulting, Jeevan offers a deep expertise across the pharmaceutical value chain, including early discovery, drug development, commercialization, and supply chain management. He excels in leveraging scientific data and AI technologies to expedite drug discovery and development, particularly in addressing unmet therapeutic needs. Jeevan is committed to driving revenue growth through accelerating market entry, streamlined supply chain operations, and strategic commercialization. At Centella AI Therapeutics, he aims to enhance customer engagement and foster innovation throughout the pharmaceutical lifecycle, achieving significant business results and advancing broader healthcare goals.',
      linkedin: 'https://www.linkedin.com/in/jeevanrebba',
    },

  ];
 
  const handleLinkedinClick = (founder) => {
    setSelectedFounder(founder);
    setShowPopup(true);
  };
 
  const closePopup = () => {
    setShowPopup(false);
    setSelectedFounder(null);
  };
 
 
  return (
    <>
     <div className="bg-gradient-to-r from-black to-[#212337] text-white px-6 sm:px-12 lg:px-24">
      <div className="mx-auto px-4 sm:px-8 py-12 tracking-[1px] opacity-75 font-sofia">
 {/* Company Description */}
 <section className="flex flex-col items-center justify-center mb-12 max-w-5xl mx-auto">
  <div className="w-full text-center">
    <h2 className="text-6xl sm:text-5xl lg:text-6xl font-bold mb-4 text-[#6FC5F0]">
      Our Team
    </h2>
    <hr className="border-t-2 border-[#6FC5F0] w-full" />
  </div>
</section>
 
 
 
{/* Our Commitment */}
<section className="flex flex-col items-center justify-center mb-12 max-w-6xl mx-auto">
  <div className="w-full text-center">
    {/* <hr className="border-t-2 border-[#6FC5F0] w-full mb-4" /> */}
  </div>
  <div className="w-full px-6"> {/* Adjusted padding */}
    <p className="text-2xl text-white sm:text-base lg:text-2xl leading-relaxed text-justify text-black">
     Centella AI team consists of over 20 professionals, including Data Scientists, Molecular Biologists, Medicinal Chemists,Informaticians and AI Engineers, bringing together a diverse range of scientific disciplines with deep experience in the drug discovery industry. We are committed to developing cutting-edge therapeutics, reinforcing our global impact on healthcare.
    </p>
  </div>
</section>
 
 
 
 
        {/* Meet Our Founders */}
        <section className="text-center mb-12">
      <div className="flex flex-wrap justify-center gap-16">
        {/* Founder 1 */}
    {/* Founder 1 */}
    <div className="flex flex-col items-center max-w-lg">
              <motion.div className="flex flex-col items-center max-w-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                <img
                  src={riyaz}
                  alt="Dr. Riyaz Syed"
                  className="w-48 h-48 mb-4 object-cover rounded-full"
                  onClick={() => handleLinkedinClick(founders[0])}
                />
                <div className="text-center"> {/* Change to text-center for centering */}
                  <h3 className="text-3xl font-bold">Dr. Riyaz Syed</h3>
                  <p className="text-cyan-400 text-2xl">Founder & CEO</p>
                  <div className="flex justify-center mt-2"> {/* Change to justify-center for centering */}
                    <a
                      className="text-teal-400 hover:text-teal-600 cursor-pointer"
                      href='https://www.linkedin.com/in/dr-riyaz-syed-b1163b4/'
                    >
                      <FaLinkedin size={24} />
                    </a>
                  </div>
                </div>
              </motion.div>
 
            </div>
 
 
{/* Founder 2 */}
<div className="flex flex-col items-center max-w-lg">
<motion.div className="flex flex-col items-center max-w-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
  <img
    src={poorna}
    alt="Dr. Poornachandra"
    className="w-48 h-48 mb-4 object-bottom cursor-pointer rounded-full"
    onClick={() => handleLinkedinClick(founders[1])}
  />
  <div className="text-center"> {/* Change to text-center for centering */}
    <h3 className="text-3xl font-bold">Dr. Poornachandra</h3>
    <p className="text-cyan-400 text-2xl">Co-Founder & Chief Scientist</p>
    <div className="flex justify-center mt-2"> {/* Change to justify-center for centering */}
      <a
        className="text-teal-400 hover:text-teal-600 cursor-pointer"
        href='https://www.linkedin.com/in/dr-poornachandra-y-1408b847/'
      >
        <FaLinkedin size={24} />
      </a>
    </div>
  </div>
  </motion.div>
</div>
 
      </div>
 
 
      {/* Popup Modal */}
      {showPopup && (
            <div className="fixed inset-0 mt-32 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-4 rounded-lg max-w-md w-full h-full overflow-auto">
                <img
                  src={selectedFounder.image}
                  alt={selectedFounder.name}
                  className="w-72 h-72 ml-12 mb-4 object-cover rounded-full"
                />
                <h3 className="text-3xl font-bold text-black">{selectedFounder.name}</h3>
                <p className="text-black text-2xl">{selectedFounder.designation}</p>
                <p className="mt-4 text-black text-lg overflow-auto text-justify max-h-64">{selectedFounder.description}</p>
                <div className="flex justify-center mt-4">
                  <a
                    href={selectedFounder.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-teal-400 hover:text-teal-600"
                  >
                    LinkedIn Profile
                  </a>
                </div>
                <button
                  onClick={closePopup}
                  className="mt-4 bg-teal-400 text-white py-2 px-4 rounded"
                >
                  Close
                </button>
              </div>
            </div>
          )}
    </section>
 
 
        {/* Meet our advisors  */}
        <section >
  <div className="flex flex-col gap-12 mt-24">
    {/* Row 1 */}
    <div className="flex flex-wrap justify-around gap-8">
      {/* Team Member 1 */}
      <div className="flex flex-col items-center max-w-xs">
        <motion.div className="flex flex-col items-center max-w-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
        <img
          src={venkat}
          alt="Venkat"
          className="w-48 h-48 rounded-full mb-4 object-cover"
        />
        <div className="text-center">
        <div className="text-3xl font-bold">Venkateswarlu.N</div>
          <p className="text-cyan-400 text-2xl"> Advisor</p>
          <div className="flex justify-center mt-2"> {/* Change to justify-center for centering */}
      <a
        className="text-teal-400 hover:text-teal-600 cursor-pointer"
        href='https://www.linkedin.com/in/venkatin/'
      >
        <FaLinkedin size={24} />
      </a>
    </div>
        </div>
        </motion.div>
      </div>
      {/* Team Member 2 */}
      <div className="flex flex-col items-center max-w-xs">
      <motion.div className="flex flex-col items-center max-w-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
        <img
          src={jeevan}
          alt="jeevan"
          className="w-48 h-48 rounded-full  cursor-pointer mb-4 object-cover overflow-hidden"
          onClick={() => handleLinkedinClick(founders[2])}
        />
        <div className="text-center">
          <h3 className="text-3xl font-bold">Jeevan Rebba</h3>
          <p className="text-cyan-400 text-2xl">Chief Strategy Officer</p>
          <div className="flex justify-center mt-2"> {/* Change to justify-center for centering */}
      <a
        className="text-teal-400 hover:text-teal-600 cursor-pointer"
        href='https://www.linkedin.com/in/jeevanrebba/'
      >
        <FaLinkedin size={24} />
      </a>
    </div>
        </div>
        </motion.div>
      </div>
      {/* Team Member 3 */}
      <div className="flex flex-col items-center max-w-xs">
      <motion.div className="flex flex-col items-center max-w-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
        <img
          src={antony}
          alt="antony"
          className="w-48 h-48 rounded-full mb-4 object-cover"
        />
        <div className="text-center">
          <h3 className="text-3xl font-bold">Dr. Antonio Brunetti</h3>
          <p className="text-cyan-400 text-2xl">Scientific Advisor</p>
 
      <a
        className="text-teal-400 flex justify-center mt-2 hover:text-teal-600 cursor-pointer"
        href='https://www.linkedin.com/in/antonio-brunetti-3729b31b/'
      >
        <FaLinkedin size={24} />
      </a>
 
        </div>
        </motion.div>
      </div>
    </div>
  </div>
</section>
 
 
        {/* Meet Our Team */}
        <section className="text-center mt-32">
 
  <div className="flex flex-col gap-12">
    {/* Row 1 */}
    <div className="flex flex-wrap justify-around gap-8">
      {/* Team Member 1 */}

      <div className="flex flex-col items-center max-w-xs">
      <motion.div className="flex flex-col items-center max-w-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
        <img
          src={rami}
          alt="Niharika Vulise"
          className="w-48 h-48 rounded-full mb-4 object-cover"
        />
        <div className="text-center">
          <h3 className="text-3xl font-bold">Rami Balasubramanian</h3>
          <p className="text-cyan-400 text-2xl">Scientist-AI Drug Discovery</p>
        </div>
        </motion.div>
      </div>

      <div className="flex flex-col items-center max-w-xs">
      <motion.div className="flex flex-col items-center max-w-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
        <img
          src={irfan}
          alt="Irfan Mirza Baig"
          className="w-48 h-48 rounded-full mb-4 object-cover"
        />
        <div className="text-center">
          <h3 className="text-3xl font-bold">Irfan Mirza Baig</h3>
          <p className="text-cyan-400 text-2xl">Product Associate</p>
          
        </div>
        </motion.div>
      </div>
      {/* Team Member 2 */}
      <div className="flex flex-col items-center max-w-xs">
      <motion.div className="flex flex-col items-center max-w-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
        <img
          src={pavanreddy}
          alt="Pavan Reddy"
          className="w-48 h-48 rounded-full mb-4 object-cover"
        />
        <div className="text-center">
          <h3 className="text-3xl font-bold">Pavan Reddy</h3>
          <p className="text-cyan-400 text-2xl">AI Engineer</p>
        </div>
        </motion.div>
      </div>
     
      {/* Team Member 3 */}
      <div className="flex flex-col items-center max-w-xs">
      <motion.div className="flex flex-col items-center max-w-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
        <img
          src={sadiq}
          alt="Mohammad Sadiq"
          className="w-48 h-48 rounded-full mb-4 object-cover"
        />
        <div className="text-center">
          <h3 className="text-3xl font-bold">Mohammad Sadiq</h3>
          <p className="text-cyan-400 text-2xl">FullStack Developer</p>
        </div>
        </motion.div>
      </div>
    </div>
    {/* Row 2 */}
    <div className="flex flex-wrap justify-around gap-8">
      {/* Team Member 4 */}
      <div className="flex flex-col items-center max-w-xs">
      <motion.div className="flex flex-col items-center max-w-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
        <img
          src={pavan}
          alt="Pavan Kumar"
          className="w-48 h-48  rounded-full mb-4 object-cover"
        />
        <div className="text-center">
          <h3 className="text-3xl font-bold">Pavan Kumar</h3>
          <p className="text-cyan-400 text-2xl">Frontend Developer</p>
        </div>
        </motion.div>
      </div>
      {/* Team Member 5 */}
      <div className="flex flex-col items-center max-w-xs">
      <motion.div className="flex flex-col items-center max-w-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
        <img
          src={niharika}
          alt="Niharika Vulise"
          className="w-48 h-48 rounded-full mb-4 object-cover"
        />
        <div className="text-center">
          <h3 className="text-3xl font-bold">Niharika Vulise</h3>
          <p className="text-cyan-400 text-2xl">Informatician</p>
        </div>
        </motion.div>
      </div>
      {/* Team Member 6 */}
      <div className="flex flex-col items-center max-w-xs">
      <motion.div className="flex flex-col items-center max-w-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
        <img
          src={pavani}
          alt="Pavani"
          className="w-48 h-48 rounded-full mb-4 object-cover"
        />
        <div className="text-center">
          <h3 className="text-3xl font-bold">Pavani</h3>
          <p className="text-cyan-400 text-2xl">Informatician</p>
        </div>
        </motion.div>
      </div>

      <div className="flex flex-col items-center max-w-xs">
      <motion.div className="flex flex-col items-center max-w-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
        <img
          src={gayatri}
          alt="Pavani"
          className="w-48 h-48 rounded-full mb-4 object-cover"
        />
        <div className="text-center">
          <h3 className="text-3xl font-bold">Gayatri Iragavarapu</h3>
          <p className="text-cyan-400 text-2xl">AI Research Intern</p>
        </div>
        </motion.div>
      </div>
    </div>
  </div>
</section>
 
<section className="text-center mt-16 max-w-3xl mx-auto">
          <div className='flex flex-col items-center justify-center'>
            <img className="text-center  mt-8 animate-pulse" alt='' src={Aiicon} height={140} width={140} />
          </div>
 
 
          <div className='mt-12'>
            <span className='flex flex-row items-center justify-center '>
              <img src='https://cdn-icons-png.flaticon.com/128/9664/9664292.png' height={60} width={60} className='relative mb-12 ' />
              <p className="text-2xl leading-relaxed mx-4 text-center italic">
                Transforming scientific insights into life-saving therapies
              </p>
              <img className="transform scale-x-[-1] relative mb-12" src='https://cdn-icons-png.flaticon.com/128/9664/9664292.png' height={60} width={60} />
            </span>
          </div>
 
        </section>
      
      </div>
     
    </div>
    <div>
      <Footer/>
    </div>
    </>
   
  );
};
 
export default AboutUs;
 
 